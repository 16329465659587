import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNil } from 'lodash'

@Component({
  selector: 'app-acceptance-detail',
  templateUrl: './acceptance-detail.component.html',
  styleUrls: ['./acceptance-detail.component.scss']
})
export class AcceptanceDetailComponent implements OnInit {


  ipInfos: Array<KeyValue> = [];

  platforms: Array<KeyValue> = [];

  bankInfo: Array<KeyValue> = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    const { ipInfo, platform, extraData, ...d } = this.data;

    this.ipInfos = this.SetKeyValue(ipInfo);
    this.platforms = this.SetKeyValue(platform);
    this.bankInfo = extraData.payload ? this.SetKeyValue({ ...extraData.payload, amount: extraData.amount }) : [];
  }

  ngOnInit(): void {
  }

  /**
   * See the obj and returns the values and the keys
   * @param obj
   */
  private SetKeyValue(obj): Array<KeyValue> {

    let container: Array<KeyValue> = [];

    const keys: string[] = Object.keys(obj);

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      const v = obj[k];
      const value = isNil(v) || !v ? " - " : v;
      container.push({ key: k, value: value } as KeyValue);
    }
    return container;
  }

  existsBankInfo() {
    return this.bankInfo.some(b => b.key === 'bankAccount');
  }
}

export interface KeyValue {
  key: string,
  value: any
}
