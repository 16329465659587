import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AngularFireStorage } from "@angular/fire/storage";
import { BatchData, OrderService } from "../../shared/order.service";
import { UiService } from "@a2system/angular/material";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NotificationsService } from "../../shared/notifications.service";

@Component({
  selector: 'app-shipping-report-dialog',
  templateUrl: './shipping-report-dialog.component.html',
  styleUrls: ['./shipping-report-dialog.component.scss']
})
export class ShippingReportDialogComponent implements OnInit {

  updateOrdersEnabled = false;
  pdfReportUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage,
    private ui: UiService,
    private orderService: OrderService,
    private cdRef: ChangeDetectorRef,
    private functions: AngularFireFunctions,
    private notifications: NotificationsService
  ) {
  }

  ngOnInit(): void {
  }

  downloadShippingReport() {
    const storageRef = this.storage.ref('shippingReports/');
    const fileRef = storageRef.child(`pedidos-${this.data.shippingCourier}.pdf`);

    try {
      fileRef.getDownloadURL().subscribe(async url => {
        this.pdfReportUrl = url;
        this.updateOrdersEnabled = true;
        window.open(url, '_blank');
      });
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  }

  async updateOrdersStatus() {
    const ordersToUpdate = [...this.data.ordersAboutToUpdate, ...this.data.ordersWillNotUpdate];
    if (ordersToUpdate.length === 0) {
      this.ui.toast(`No hay pedidos que actualizar`, 8000);
      return;
    }
    let totalProcessed = 0;
    const batchSize = 200;
    const batches = [];
    let index = 0;
    while (index < ordersToUpdate.length) {
      batches.push(ordersToUpdate.slice(index, index + batchSize));
      index += batchSize;
    }
    const batchData: BatchData = {
      ids: ordersToUpdate.map(order => order.id),
      property: 'processStatus',
      value: 'shipped'
    };

    this.ui.spinner.show();

    try {
      let resp;
      for (const batch of batches) {
        batchData.ids = batch.map(order => order.id);
        resp = await this.orderService.batchUpdate(batchData);
        totalProcessed += resp.count;
      }
      this.ui.toast(`Se actualizaron ${totalProcessed} registros`, 8000);
    } catch (err) {
      this.ui.toast('Fallo actualizando registros');
      console.log('Fallo actualizando registros', err);
    } finally {
      this.cdRef.detectChanges();
      this.updateOrdersEnabled = false;
      this.ui.spinner.reset();
      await this.notifications.sendOrdersToSendReport(this.pdfReportUrl, this.data.shippingCourier);
    }

  }
}
