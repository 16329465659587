import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ElasticSearchService } from './elastic-search.service';
import { firestore } from 'firebase/app';
import { map, share, shareReplay, tap, throttleTime } from 'rxjs/operators';
import { Printer, PrinterStatus, PrintRequirement, RequirementStatus } from '../models/printer-model';
import { AngularFireFunctions } from '@angular/fire/functions';
import { toDate } from './utils/utils';
import { OrderService } from './order.service';
import { Order, OrderGroupPrintStatus, OrderPrintableStatus, OrderProcessStatus, PageSize } from '../models/models';
import { RangeDate } from '../admin/printers/printers-activity-table/printers-activity-table.component';
import { PrintJob2 } from '../models/print-job2.model';
import { PrintJob } from '../models/print-job.model';


@Injectable({
  providedIn: 'root'
})
export class PrintersService {
  private collection: AngularFirestoreCollection<any>;

  constructor(
    private db: AngularFirestore,
    private es: ElasticSearchService,
    private fns: AngularFireFunctions,
    private orderService: OrderService,

  ) {
    this.collection = this.db.collection<any>('printers');
  }

  API_CLEAR_REQUIREMENTS = 'api/requirements/clear';

  callPurgePrinter = this.fns.httpsCallable('purgePrinter');



  //opt { idField: 'id' }
  getAll(opt = {}): Observable<Printer[]> {
    return this.collection.valueChanges(opt);
  }


  //opt { idField: 'id' }
  getAllWithIdSortedByLabel(opt = {}): Observable<Printer[]> {
    return this.db.collection<Printer>('printers', ref => ref.orderBy('label', 'asc')).valueChanges(opt)
  }


  // TODO: make sure this one is not used anymore...
  getAllEnabledWithUserID(userId: string): Observable<Printer[]> {
    return this.db.collection<Printer>('printers', ref => ref
      .where('status', '==', Printer.Status.ENABLED)
      .where('isVisible', '==', true)
      .where('userIds', 'array-contains', userId)
      // .orderBy('label','asc') 
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Printer;
        const id = a.payload.doc.id;
        return { id, ...data };
      }).sort((a, b) => a.label.localeCompare(b.label.toString()))
      ))
  }

  getSinglePrinterStatus$(printerId: string): Observable<PrinterStatus> {
    return this.db.doc<PrinterStatus>(`printers/${printerId}/meta/printerStatus`)
      .snapshotChanges().pipe(
        // tap(_ => console.log('got printer status', _)),
        map(action => action.payload.data()),
        share(), // this does die when ref count == 0 (subscribers count)
        // shareReplay(1), // TODO: this is wrong, subscription won't die with take(1) when using sharedReplay here
      );
  }

  // getPrinterStatuses$(userId: string): Observable<PrinterStatus[]> {
  //   // use includeVisibility:true when requesting all printers for a regular user
  //   // except for super-admin (use includeVisibility:false to see all printers)
  //   // console.log("Userid", userId);
  //   return this.db.collectionGroup<PrinterStatus>('meta', ref => {
  //     let _ref = ref
  //       .where('id', '==', 'printerStatus')
  //       .where('status', '==', Printer.Status.ENABLED);
  //     if (userId) {
  //       _ref = _ref.where('userIds', 'array-contains', userId);
  //     }
  //     return _ref;
  //   }).snapshotChanges().pipe(
  //     // tap(_ => console.log('printerStatus', _?.length)),
  //     map(actions => {
  //       return actions.map(a => {
  //         const data = a.payload.doc.data() as PrinterStatus;
  //         data.deviceId = a.payload.doc.ref.parent.parent.id; // beware!! this overrides stored (unused) value (...on printer, actually, not on status)
  //         data.estimatedSeconds = 60 * data.printSides / data.pagesPerMinute
  //         const id = a.payload.doc.id;
  //         return { id, ...data };
  //       }).sort((a, b) => a.label.localeCompare(b.label.toString()));
  //     }),
  //     throttleTime(2 * 1000, undefined, { leading: true, trailing: true }),
  //     // tap(_ => console.log('THROTTLE 1A')),
  //     shareReplay(1), // TODO: share()??
  //     // tap(_ => console.log('THROTTLE 1B')),
  //   )
  // }

  // getPrinterStatus$(userId: string): Observable<PrinterStatus[]> {
  getPrinterStatus$(userId: string): Observable<PrinterStatus[]> {
    // console.log("Userid", userId);
    return this.db.collectionGroup<PrinterStatus>('meta', ref => ref
      .where('id', '==', 'printerStatus')
      .where('status', '==', Printer.Status.ENABLED)
      .where('userIds', 'array-contains', userId)
    ).snapshotChanges().pipe(
      // tap(_ => console.log('printerStatus', _?.length)),
      throttleTime(2 * 1000, undefined, { leading: true, trailing: true }),
      // tap(_ => console.log('THROTTLE after')),
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as PrinterStatus;
          data.deviceId = a.payload.doc.ref.parent.parent.id;
          data.estimatedSeconds = 60 * data.printSides / data.pagesPerMinute
          const id = a.payload.doc.id;
          return { id, ...data };
        }).sort((a, b) => a.label.localeCompare(b.label.toString()));
      }),
      // throttleTime(2 * 1000, undefined, { leading: true, trailing: true }),
      // tap(_ => console.log('THROTTLE 1A')),
      // shareReplay(1), // TODO: share()??  // this makes the printer selector show empty
      // share(), // TODO: share()??
      // tap(_ => console.log('THROTTLE 1B')),
    )
  }

  // getAvailablePrinters_$(considerVisibility: boolean = true): Observable<PrinterStatus[]> {
  //   // considerVisibility: whether printers should be filteres by visibility as well
  //   return this.getPrinterStatuses$({ considerVisibility });
  // } // NOP! somehow this shares replay with a different query???

  getAvailablePrinters$(considerVisibility: boolean = true): Observable<PrinterStatus[]> {
    return this.db.collectionGroup<PrinterStatus>('meta', ref => {
      let _ref = ref
      .where('id', '==', 'printerStatus')
      .where('status', '==', Printer.Status.ENABLED);
      if (considerVisibility) {
        _ref = _ref.where('isVisible', '==', true); // not needed here
      }
      return _ref;
    }).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as PrinterStatus;
        data.deviceId = a.payload.doc.ref.parent.parent.id;
        const id = a.payload.doc.id;
        return { id, ...data };
      }).sort((a, b) => a.label.localeCompare(b.label.toString()))),
      shareReplay(1),
    )
  }
  // getAvailablePrinters$(): Observable<PrinterStatus[]> {
  //   return this.db.collectionGroup<PrinterStatus>('meta', ref => ref
  //     .where('id', '==', 'printerStatus')
  //     .where('status', '==', Printer.Status.ENABLED)
  //   ).snapshotChanges().pipe(
  //     map(actions => actions.map(a => {
  //       const data = a.payload.doc.data() as PrinterStatus;
  //       data.deviceId = a.payload.doc.ref.parent.parent.id;
  //       const id = a.payload.doc.id;
  //       return { id, ...data };
  //     }).sort((a, b) => a.label.localeCompare(b.label.toString()))),
  //     shareReplay(1),
  //   )
  // }

  setUserPrinter({ userId, printerId, isAssigned = true, isStatus = true }): any {
    const userIds = isAssigned
      ? firestore.FieldValue.arrayUnion(userId)
      : firestore.FieldValue.arrayRemove(userId);
    return this.upsert({
      id: isStatus ? `${printerId}/meta/printerStatus` : printerId,
      userIds,
    })
  }

  setPrinterStatusOptions(printerId, options) {
    return this.upsert({
      id: `${printerId}/meta/printerStatus`,
      ...options
    })
  }

  getbyOrder$(orderId: string): Observable<PrintRequirement[]> {
    return this.db.collection<PrintRequirement>('requirements', ref => ref
      .where('order.id', '==', orderId)
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as PrintRequirement;
        const id = a.payload.doc.id;
        data.order.createdAt = toDate(data.order.createdAt)
        data.createdAt = toDate(data.createdAt);
        return { id, ...data };
      })
        .sort(this.dateSort('createdAt', "DESC"))
        // shareReplay(1) // TODO: misplacement of shareReply (originally uncommented)
      ))
  }

  getRequirementById$(requirementId: string): Observable<PrintRequirement> {
    return this.db.collection<PrintRequirement>('requirements').doc(requirementId).get().pipe(
      map(snapshot => {
        const data = snapshot.data() as PrintRequirement;
        data.order.createdAt = toDate(data.order.createdAt)
        data.createdAt = toDate(data.createdAt);
        return data; // new requirements already include id
      })
    );
  }

  getBundlePrintJobs2$(bundleId: string, filterOutCompleted: boolean = false): Observable<PrintJob2[]> {
    return this.db.collection<PrintJob2>('printJobs2', ref => {
      let query = ref.where('bundleId', '==', bundleId);
      if (filterOutCompleted) {
        const statuses = Object.values(PrintJob.Status).filter(e => e !== PrintJob.Status.COMPLETED);
        query = query.where('status', 'in', statuses);
      }
      return query;
    }
      // .orderBy('queueOrder', 'asc') // no index for this...
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as PrintJob2;
        // data.createdAt = toDate(data.createdAt);
        return data;
      }).sort((a, b) => a.queueOrder - b.queueOrder)
      )
    )
  }

  dateSort = (property, direction = "ASC") => (a, b) => { return direction == "ASC" ? a[property] - b[property] : b[property] - a[property] };

  //Consultar requirements 
  getPrinterRequirements$(): Observable<Partial<PrintRequirement>[]> {
    return this.db.collection<PrintRequirement>('requirements', ref => ref
      .where('status', '!=', RequirementStatus.CLEARED)
      // .where('order.contains','array-contains', ['F']) // no way to negate!
      // .orderBy('label','asc') 
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {

        const data = a.payload.doc.data() as PrintRequirement;
        const id = a.payload.doc.id;
        data.order.createdAt = toDate(data.order.createdAt)
        data.createdAt = toDate(data.createdAt);
        return { id, ...data };
      })
        // shareReplay(1) // TODO: misplacement of shareReply (originally uncommented)
      ))
  }

  upsert(uData): Promise<any> {
    const { id, ...data } = uData;
    if (!id) { data.createdAt = firestore.FieldValue.serverTimestamp(); }
    data.updatedAt = firestore.FieldValue.serverTimestamp();
    return (id)
      ? this.collection.doc(id).update(data)
      : this.collection.add(data);
  }

  delete(id: string) {
    return this.collection.doc<any>(id).delete();
  }

  purgePrinter(printer): Promise<any> {
    const printerId = printer.id;
    return this.callPurgePrinter({ printerId }).toPromise();
  }

  createRequirement(requirement: PrintRequirement): Promise<any> {
    requirement.id = this.createId();
    requirement.createdAt = firestore.FieldValue.serverTimestamp();
    requirement.updatedAt = firestore.FieldValue.serverTimestamp();
    const collection = this.db.collection<any>('requirements');
    return collection.doc(requirement.id).set(requirement);
  };

  createRequirements(requirements: Array<PrintRequirement>): Promise<any> {
    var batch = this.db.firestore.batch();
    requirements.slice(0, 500).forEach(requirement => {
      requirement.id = this.createId();
      requirement.createdAt = firestore.FieldValue.serverTimestamp();
      requirement.updatedAt = firestore.FieldValue.serverTimestamp();
      const reqRef = this.db.firestore.collection('requirements').doc(requirement.id);
      batch.set(reqRef, requirement);
    });
    return batch.commit()
      .then(res => {
        return { res, count: requirements.length }
      });
  }

  createId() {
    return this.db.createId();
  }

  deleteRequirement(requirement: PrintRequirement): Promise<any> {
    const collection = this.db.collection<any>('requirements');
    const data = {
      id: requirement.id,
      status: RequirementStatus.CLEARED,
      updatedAt: firestore.FieldValue.serverTimestamp()
    }
    return collection.doc(requirement.id).update(data)
  }

  async blockOrder(order: Order, block: boolean = true) {
    const data = {
      id: order.id,
      printableStatusErrored: !!block,
    };
    await this.orderService.upsert(data);
  }

  async deleteOrder(order: Order) { // delete from print list
    const data = {
      id: order.id,
      printableStatus: OrderPrintableStatus.DISCARDED,
    };
    await this.orderService.upsert(data);
  }

  async resetOrderPrintProgress(order: Order, requirements: Array<Partial<PrintRequirement>> = null) {
    // update order
    const printStatusGroups = order.printStatusGroups ?? [];
    printStatusGroups.forEach(e => {
      e.status = OrderGroupPrintStatus.PENDING;
      e.bundleId = null;
    });
    const _order = {
      id: order.id,
      printStatusGroups: printStatusGroups,
      processStatus: OrderProcessStatus.PENDING,
      printableStatus: OrderPrintableStatus.PENDING,
    };
    // update order, delete requirements from view
    await this.orderService.upsert(_order);
    await this.deleteRequirements(requirements as Array<PrintRequirement>);
    return printStatusGroups;
  }

  async resetOrderPrintStatus(orderId: string, requirements: Array<Partial<PrintRequirement>>, isPrintableStatusErrored = false): Promise<any> {

    let _order = {
      id: orderId,
      processStatus: OrderProcessStatus.PENDING,
      printableStatus: OrderPrintableStatus.PENDING,
      printableStatusErrored: isPrintableStatusErrored,
    }

    // update order, delete requirements from view
    await this.orderService.upsert(_order);
    await this.deleteRequirements(requirements as Array<PrintRequirement>);
  }

  async deleteRequirements(requirements: Array<PrintRequirement> = []): Promise<any> {
    // // const MAX_WRITES_PER_BATCH = 500; 
    // const MAX_WRITES_PER_BATCH = 100; // 500 complains about "max 500 allowed", even with only 300 rows  :-/
    // const batches = chunk(requirements, MAX_WRITES_PER_BATCH);
    // const commitBatchPromises = [];
    // // console.log(`requirements: ${requirements.length}`);
    // // console.log(`batches: ${batches.length}`);

    // batches.forEach(batch => {
    //   const writeBatch =  this.db.firestore.batch();
    //   // batch.forEach(doc => writeBatch.delete(this.db.firestore.collection('requirements').doc(doc.id)));
    //   batch.forEach(doc => {
    //     const ref = this.db.firestore.collection('requirements').doc(doc.id);
    //     const data = {
    //       status: RequirementStatus.CLEARED,
    //       updatedAt: firestore.FieldValue.serverTimestamp(),
    //     };
    //     writeBatch.update(ref, data);
    //   });
    //   commitBatchPromises.push(writeBatch.commit());
    // });
    // return Promise.all(commitBatchPromises);
    const requirementIds = requirements.map(e => e.id);
    return this.fns.httpsCallable(this.API_CLEAR_REQUIREMENTS)({ requirementIds }).toPromise();
  }


  /**
   * Pregunta a elastic search, devuelve basado en lo que le damos
   * 
   * @param paginator 
   * @param sort 
   * @param filter 
   * @param options 
   */
  async query(paginator, sort, filter, options?) {
    const index = 'printers'
    const extras = {}
    // console.log("👍 QUERY on index =>",index,".........");
    let _options: any = null;

    if (options?.body) {
      _options = {
        body: true
      }
    }
    // console.log("hola", {paginator, sort, filter, options});
    const body = this.es.mtToBody(paginator, sort, filter, extras);

    ///⛔️ PATCH - ask only for _source fields. this should be part of mtToBody
    let _body = body as any;
    // _body._source=[
    //   'label','port','statusInfo.tooltip','status','printStatusInfo','makeModel',
    //   'colorSupported','users','id','updatedAt'
    // ];
    return this.es.query(index, _body, (item) => {
      // return this.es.query(index , body, (item) => {
      /// PATCH - END ⛔️

      if (item.createdAt) {
        item.createdAt = new Date(item.createdAt._seconds * 1000);
      } else {
        // console.warn("🛑🛑🛑_____REVISAME, FALTA CREATED AT");
      }
      return item;
    },);
  }

  onChange(): Observable<any> {
    return this.db
      .collection<any>('refresh')
      .doc('printers')
      .valueChanges();
  }

  getAllTableActivity(): Observable<Printer[]> {
    return this.db.collection<Printer>('printers', ref =>
      ref.where('printsCount.snmpCode', '>', '')
    )
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Printer;
          const id = a.payload.doc.id;
          const doc = { id, ...data };
          return doc;
        }).sort((a, b) => a.label.localeCompare(b.label.toString())))
      );
  }

  queryCountersTableActivity(dateRange: RangeDate): Observable<any[]> {
    const index = 'printsCounts';
    const filter = [
      this.es._toDateRangeFilter_old('createdAt._seconds', dateRange),
    ];
    const body = {
      query: { bool: { filter: filter } },
      size: 0,
      aggs: {
        by_printer: {
          terms: { field: 'printer.id', size: 1000 },
          aggs: {
            max: { max: { field: 'currCount' } },
            min: { min: { field: 'prevCount' } }
          }
        }
      }
    };
    return this.es._API_search({ index, body }).pipe(
      map(data => data.aggregations.by_printer.buckets.map(
        b => {
          const min = b.min.value ?? 0;
          const max = b.max.value ?? 0;
          return { id: b.key, value: Math.max(0, max - min) };
        }
      )),
      // tap( data => console.log(data)),
    );
  }
}
