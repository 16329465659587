import { Injectable } from '@angular/core';
import { AngularFireFunctions } from "@angular/fire/functions";
import { AngularFireStorage } from "@angular/fire/storage";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  API_SEND_INTERNAL_EMAIL = 'api/notifications/sendInternalEmail';

  constructor(private functions: AngularFireFunctions, private storage: AngularFireStorage) {
  }

  _API_send_internal_email(data = null) {
    return this.functions.httpsCallable(this.API_SEND_INTERNAL_EMAIL)(data).toPromise();
  }

  notifyPriceChanges(changes, printing = false) {
    let htmlContent = ``;
    if (changes.length) {
      changes.forEach(chg => {
        if (chg.oldValue && chg.newValue) {
          htmlContent += `<p>${chg.description}: ${chg.oldValue}€  -->  ${chg.newValue}€</p>`;
        } else {
          htmlContent += `<p>${chg.description}`;
        }
      });
      const emailData = {
        adminEmailsTo: ['juanbalastegui@copyfly.es', 'gestionenvios@copyfly.es', 'info@copyfly.es'],
        header: 'Informe de cambio de precios',
        message: 'Se han modificado los siguientes ajustes:',
        html: htmlContent
      };

      this._API_send_internal_email(emailData);
    }
  }

  async sendOrdersToSendReport(pdfReportUrl, shippingCourier) {
    const data = {
      adminEmailsTo: ['juanbalastegui@copyfly.es', 'gestionenvios@copyfly.es'],
      header: 'Reporte con información de envíos',
      message: 'En el archivo adjunto se encuentra el pdf con la información diaria de envíos',
      attachments: [],
    };

    const base64PDF = await this.convertToBase64(pdfReportUrl);
    data.attachments.push({
      content: base64PDF,
      filename: `reportePdf-${shippingCourier}.pdf`,
      type: 'application/pdf',
      disposition: 'attachment',
    });
    const urlExcel = await this.storage.ref(`shippingReports/pedidos-${shippingCourier}.xlsx`).getDownloadURL().toPromise();
    const base64Excel = await this.convertToBase64(urlExcel)
    data.attachments.push({
      content: base64Excel,
      filename: `reporteExcel-${shippingCourier}.xlsx`,
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      disposition: 'attachment',
    });
    this._API_send_internal_email(data)
      .then(res => {
      });
  }

  async convertToBase64(url: string): Promise<string | ArrayBuffer | null> {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Remover la parte que no es necesaria del resultado Base64
        const base64result = (reader.result as string).split(',')[1];
        resolve(base64result);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }
}
