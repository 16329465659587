<form [formGroup]="supportForm" id="idFormSupport" (submit)="createClickupTask();" fxLayout="column"
      fxLayoutAlign="center space-between">
  <mat-form-field appearance="outline" *ngIf="data.orderId === undefined">
    <mat-label>Escoge una opción</mat-label>
    <mat-select formControlName="type" name="type">
      <mat-option value="error">
        Informar de un error
      </mat-option>
      <mat-option value="suggestion">
        Aportar una sugerencia
      </mat-option>
    </mat-select>
    <mat-error *ngIf="supportForm.controls.type.invalid">
      <mat-icon>info</mat-icon>
      {{ ui.getErrorMessage(supportForm.get('type'), validationMessages) | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Departamento</mat-label>
    <mat-select formControlName="department" (selectionChange)="selectionChanged($event, 'department')">
      <mat-option value="customer">
        Atención al cliente
      </mat-option>
      <mat-option value="development">
        Desarrollo
      </mat-option>
      <mat-option value="marketing">
        Marketing
      </mat-option>
      <mat-option value="design">
        Diseño
      </mat-option>
      <mat-option value="admin">
        Administración
      </mat-option>
      <mat-option value="production">
        Producción
      </mat-option>
    </mat-select>
    <mat-error *ngIf="supportForm.controls.department.invalid">
      <mat-icon>info</mat-icon>
      {{ ui.getErrorMessage(supportForm.get('type'), validationMessages) | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Título</mat-label>
    <span id="titleHeader"
          *ngIf="(currentDisplayName || currentDepartment) && supportForm.get('title').value.length">{{currentDepartment}}
      - {{currentDisplayName}}</span>
    <input id="titleInput" type="text" [readonly]="data.orderId" matInput formControlName="title">
    <mat-error *ngIf="supportForm.get('title').invalid">
      <mat-icon>info</mat-icon>
      {{ ui.getErrorMessage(supportForm.get('title'), validationMessages) | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Descripción</mat-label>
    <textarea matInput formControlName="description"></textarea>
    <mat-error *ngIf="supportForm.get('description').invalid">
      <mat-icon>info</mat-icon>
      {{ ui.getErrorMessage(supportForm.get('description'), validationMessages) | translate }}
    </mat-error>
  </mat-form-field>
  <!--  <button type="button" mat-raised-button (click)="fileInput.click()">Adjuntar archivos (opcional)</button>-->
  <input hidden (change)="onFileSelected($event)" #fileInput type="file" multiple>
  <div *ngIf="attachments.length > 0">
    <ul>
      <li *ngFor="let attachment of attachments">
        {{attachment.file.name}}
      </li>
    </ul>
  </div>
  <button mat-raised-button color="primary" type="submit" [disabled]="!supportForm.valid">Enviar</button>


</form>
