import { UiService } from '@a2system/angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ActivityLog } from 'src/app/models/activity-log.model';
import { Order } from 'src/app/models/models';
import { ActivityLogService } from 'src/app/shared/activity-log.service';
import { OrderService } from 'src/app/shared/order.service';

@Component({
  selector: 'app-logs-dialog',
  templateUrl: './logs-dialog.component.html',
  styleUrls: ['./logs-dialog.component.scss']
})
export class LogsDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LogsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private ui: UiService,
    private activityLogService: ActivityLogService,
    private orderService: OrderService
  ) { }

  order$ = this.orderService.get(this.data.row.id).pipe(
    // tap(console.log),
    shareReplay(1)
  );
  activityLogs$: Observable<ActivityLog[]> = this.activityLogService.getbyOrder$(this.data.row.id).pipe(
    // tap(console.log),
    shareReplay(1)
  );

  combo$ = combineLatest([this.order$,this.activityLogs$]).pipe(
    map(([_order,_activityLogs]) => {
      // console.log([_order,_activityLogs]);
      const clientLogs = _order.cart.items.map((item,index)=>({
        index,
        comment:item.product?.printingGroup?.comment,
        product:item.product
      }))
      .filter(item=>item.product?.printingGroup?.comment);

      const adminLogs = _activityLogs.filter((_activityLog: any) =>_activityLog?.payload?.comment)
      // console.log([[...adminLogs],[...clientLogs]]);
      return {adminLogs,clientLogs};
    })
  );
  
  ngOnInit(): void {
  }
}