import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AngularFirestore } from "@angular/fire/firestore";
import { PickupPoint } from "../../models/pickupPoint.model";
import { AngularFireFunctions } from "@angular/fire/functions";
import { distinctUntilChanged, map } from "rxjs/operators";

@Component({
  selector: 'app-pickup-points-selector',
  templateUrl: './pickup-points-selector.component.html',
  styleUrls: ['./pickup-points-selector.component.scss']
})
export class PickupPointsSelectorComponent implements OnInit {
  postalCode = '';
  errorMsg = '';
  points: PickupPoint[] = [];
  pointSelected;
  indexPointSelected = -1;
  daysToUpdatePoints = 5;
  API_UPDATE_POINTS = 'api/shipping/updatePoints';
  isLoading = false;
  availableCouriers: {} = [];

  constructor(private matDialogRef: MatDialogRef<PickupPointsSelectorComponent>, private db: AngularFirestore, private functions: AngularFireFunctions,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.db.collection('settings').doc('shippingSettings').valueChanges()
      .pipe(
        map((data: any) => data?.availableCouriersForPoints),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      )
      .subscribe((availableCouriers: any) => {
        if (availableCouriers) {
          this.availableCouriers = Object.keys(availableCouriers)
            .filter(key => availableCouriers[key].enabled)
            .reduce((result, key) => {
              result[key] = availableCouriers[key];
              return result;
            }, {});
        }
      });
  }


  protected readonly Number = Number;

  ngOnInit(): void {
  }

  async searchPoints() {
    this.points = [];
    this.isLoading = true;
    this.pointSelected = null;
    this.indexPointSelected = null;
    this.points = await this.getPointsFromPostalCode();
    if (!this.points.length) {
      this.errorMsg = 'No se han encontrado puntos de recogida en este código postal.';
    } else {
      this.errorMsg = '';
    }
    this.isLoading = false;
  }

  async getPointsFromPostalCode() {
    if (!Object.keys(this.availableCouriers).length) {
      return [];
    }

    const pointsSnap = await this.db.collection('pickupPoints').ref
      .where('postalCode', '==', this.postalCode)
      .where('company', 'in', Object.keys(this.availableCouriers))
      .get();
    return pointsSnap.docs.map(doc => {
      return doc.data() as PickupPoint;
    })
  }

  isPointDisabled(point) {
    return !!this.availableCouriers.hasOwnProperty(point.company) && this.availableCouriers[point.company]?.shippingTypes.hasOwnProperty(this.data.order.shippingType)
      && !this.availableCouriers[point.company]?.shippingTypes[this.data.order.shippingType]
  }


  selectPoint(i: number) {
    this.indexPointSelected = i;
    this.pointSelected = this.points[i];
    this.matDialogRef.close({ pointSelected: this.pointSelected })
  }

  closeDialog() {
    this.matDialogRef.close();
  }
}
