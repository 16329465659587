import { Category } from "./category.model";
import { Label } from "./label.model";
import { Price, PrintingGroup } from "./models";
import { PrintVariant } from "./printProduct.model";

// short product reference
export interface Product extends Product.Base {
  _version: number //Version del modelo de Producto
  variantId?: string;
  type: ProductType;
  subType: ProductSubType;
  listOrder: number; //Orden que aparecerá en la lista
  title: string;
  description: string;
  descriptionLines?: Array<object>;
  picturePath?: string; //???
  mediaFiles: string[];
  printingGroup?: PrintingGroup
  extra?: Object[] | any; //Lo usa el bono
  slug: string;
  seo: {
    title: string;
    description: string;
  }
  featured: boolean;
  categories: Category[]; // o Category sin array?
  labels: Label[];
  status: Product.Status;
  options: Array<Product.Option>;
  variantCount: number;
  variants?: Array<string> | Array<Product.Variant> | Array<PrintVariant>; //TODO acomodar ya que no se guardarán lso Product.Variant acá, sin embargo se usa asi en ProductUI 
  vendor: Product.VendorRef;
  createdAt?: Date;
  updatedAt?: Date;
  exclusive: boolean;
  relevance: number;
  presale: boolean;


  //solo para printProducts
  weights?: Array<any>;
  royalty?: number;
}

export enum ProductType { PRINT = 'print', STORE = 'store', WALLETBONUS = 'walletbonus', GENERIC = 'generic' }

export enum ProductSubType {
  STANDARD = 'standard',
  CUSTOM = 'custom',
  PACK = 'pack',
}

export enum ProductCustomType {
  NOTEBOOK = 'notebook',
  RESOURCE = 'resource'
}

export enum ProductCustomizableElements {
  PAPER_FORMAT = 'paperFormat',
  SHEET_NUMBER = 'sheetNumber',
  RINGS = 'rings',
  COVER_FRONT = 'coverFront',
  COVER_BACK = 'coverBack',
  PDF = 'pdf',
}

/**
 variantes

 //En catálogo se muestra el de menor precio
 //En la lista total de inventario de todos
 //Para sabe si está agotado se debe comtemplar todas las variaciones
 */

export namespace Product {
  export type Status = 'active' | 'archived' | 'draft';

  export interface Option {
    name: String;
    values: Array<String>
  }

  export interface Variant extends Product.Base {
    id: string;
    optionsValue: Array<String>;
    properties: any;
  }

  export interface Base {
    id: string;
    unitPrice: Price; // page price, binding price, product price
    discount: number;
    hideDiscount?: boolean;
    cost: Price;
    stock: number;
    soldQty: number;
    allowSaleNoStock: boolean;
    sku: string;
    supplierReference?: string;
    requiresShipping: boolean;
    weight: number;
    pictureUrl?: string;
    pictureId?: string;
    title?: string;
  }

  export const newProductBase = (product: Product): Base => {
    const {
      id,
      unitPrice,
      discount,
      cost,
      stock,
      soldQty,
      allowSaleNoStock,
      sku,
      requiresShipping,
      weight,
      pictureUrl,
      pictureId
    } = product;
    return {
      id,
      unitPrice,
      discount,
      cost,
      stock,
      soldQty,
      allowSaleNoStock,
      sku,
      requiresShipping,
      weight,
      pictureUrl,
      pictureId
    };
  }

  export interface VendorRef {
    id: string,
    name: string;
    thumbnail: string;
  }

  // short product reference
  export interface _Product {
    id: string; // reference to Product
    variantId?: string;
    type: ProductType;
    title: string;
    description: string;
    descriptionLines?: Array<object>;
    unitPrice: Price; // page price, binding price, product price
    pictureUrl?: string;
    printingGroup?: PrintingGroup
    // extra?:Object[];
    weight: number;
    extra?: any;
    vendor: Product.VendorRef;
    slug: string;
    listOrder?: number;
  }

  export const new_Product = (product: Product): _Product => {
    const {
      id,
      variantId,
      type,
      title,
      description,
      descriptionLines,
      unitPrice,
      pictureUrl,
      printingGroup,
      weight,
      extra,
      listOrder,
      vendor,
      slug
    } = product;
    return {
      id,
      variantId,
      type,
      title,
      description,
      descriptionLines,
      unitPrice,
      pictureUrl,
      printingGroup,
      weight,
      extra,
      listOrder,
      vendor,
      slug
    }
  }

  /**
   * Genera todas las variaciones posibles según las opciones.
   * @param options : Variation Options
   * @param variants : Old Variations
   * @returns variants new Variants
   */
  export const newVariants = (
    options: Array<Product.Option>,
    oldVariants: Array<Product.Variant> = [],
    defaultValue: Partial<Product.Variant>
  ): Array<Product.Variant> => {
    const variants: Array<Product.Variant> = _genVariants(options, oldVariants, defaultValue);

    return variants
  }

  //Verifica si el producto seleccionado está agotado
  export const isSoldOut = (product: Base) => !product.allowSaleNoStock && product.stock <= 0;
  //Verifica si todo el  producto contando las variaciones está agotado
  export const isAllProductSoldOut = (product: Product) => {
    const variants = product.variants as Array<Product.Variant>;
    return !product.variantCount
      ? isSoldOut(product)
      : variants.every(isSoldOut)
  }
  export const saledPrice = (product: Base) => product.discount
    ? { amt: product.unitPrice.amt / (1 + product.discount / 100), cur: product.unitPrice.cur }
    : product.unitPrice

  export const relevance = (product: Product, variants = null) => calcRelevance(product, variants);
  export const cheapestVariant = (variants: Array<Variant>) => {
    return variants.reduce((v, variant) => {
      return v && (saledPrice(variant) > saledPrice(v)) ? v : variant;
    })
  }

}

///////////Internal Functions/////////////
function _genVariants(options, _var = [], defaultValue: Partial<Product.Variant>) {
  const variants = _genNewVariants([], [], options, defaultValue) as Product.Variant[];
  return variants;
}

function _genNewVariants(key: string[], acc: Array<Product.Variant>, options: Array<Product.Option>, defaultValue?: Partial<Product.Variant>) {
  const _options = [...options]
  const option = _options.shift();

  if (options.length && option.values?.length) {
    option.values.forEach((v: string) => {
      _genNewVariants([...key, v], acc, _options, defaultValue)
    })
    return acc;
  } else {
    acc.push(_newVariant(key, defaultValue))
    return key;
  }
}

function _newVariant(optionsValue: Array<String>, defaultValue?: Partial<Product.Variant>) {
  return {
    id: null,
    optionsValue,
    unitPrice: { amt: 0, cur: 'EUR' },
    discount: 0,
    cost: { amt: 0, cur: 'EUR' },
    stock: 0,
    soldQty: 0,
    allowSaleNoStock: false,
    sku: '',
    supplierRef: '',
    requiresShipping: true,
    freeShipping: false,
    weight: 0,
    pictureUrl: null,
    pictureId: null,
    properties: null,
    ...defaultValue
  }
}

function _isAvailable(product: Product) {
  return product.allowSaleNoStock || product.stock > 0;
}

const AVAILABLE_FLAG = 0x40;
const EXCLUSIVE_FLAG = 0x10;
const FEATURED_FLAG = 0X04;

export const calcRelevance = (product: Product, variants = null) => {
  let relevance = 0;
  if (!!product.variantCount && variants) {
    relevance = variants.some(_isAvailable) ? relevance | AVAILABLE_FLAG : relevance;
  } else {
    relevance = _isAvailable(product) ? relevance | AVAILABLE_FLAG : relevance;
  }
  relevance = product.exclusive ? relevance | EXCLUSIVE_FLAG : relevance;
  relevance = product.featured ? relevance | FEATURED_FLAG : relevance;
  return relevance;
}
