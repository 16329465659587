import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from "../material.module";
import { A2matFiltersModule } from '@a2system/angular/material';

import { OnlyNumberDirective } from "./only-number.directive";
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { VerticalMenuComponent } from './vertical-menu/vertical-menu.component';
import { HeaderToolbarComponent } from './header-toolbar/header-toolbar.component';
import { NgxMaskModule } from 'ngx-mask';
import { VisualAcceptanceStatusComponent } from './visual-acceptance-status/visual-acceptance-status.component';
import { AcceptanceDetailComponent } from './acceptance-detail/acceptance-detail.component';
// import { PrintJobStatusIconComponent } from './borrar-print-job-status-icon/print-job-status-icon.component';
import { PrinterSelectorComponent } from './printer-selector/printer-selector.component';
// import { BundleJobsListComponent } from './borrar-bundle-jobs-list/bundle-jobs-list.component';
import { PipesModule } from '../pipe/pipes.module';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SupportDialogComponent } from './support-dialog/support-dialog.component';
import { ShippingHistoryDialogComponent } from './shipping-history-dialog/shipping-history-dialog.component';
import { ShippingReportDialogComponent } from './shipping-report-dialog/shipping-report-dialog.component';
import { GenerateRefundDialogComponent } from './generate-refund-dialog/generate-refund-dialog.component';
import { PickupPointsSelectorComponent } from './pickup-points-selector/pickup-points-selector.component';


@NgModule({
  declarations: [
    ConfirmDialogComponent,
    ChangePasswordComponent,
    VerticalMenuComponent,
    OnlyNumberDirective,
    AddressModalComponent,
    HeaderToolbarComponent,
    VisualAcceptanceStatusComponent,
    AcceptanceDetailComponent,
    // PrintJobStatusIconComponent,
    PrinterSelectorComponent,
    // BundleJobsListComponent,
    SearchBarComponent,
    SupportDialogComponent,
    ShippingHistoryDialogComponent,
    ShippingReportDialogComponent,
    GenerateRefundDialogComponent,
    PickupPointsSelectorComponent,
  ],
  imports: [
    MaterialModule,
    FlexLayoutModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgxMaskModule,
    A2matFiltersModule,
    PipesModule
  ],
  exports: [
    ConfirmDialogComponent,
    ChangePasswordComponent,
    VerticalMenuComponent,
    OnlyNumberDirective,
    TranslateModule,
    AddressModalComponent,
    HeaderToolbarComponent,
    NgxMaskModule,
    A2matFiltersModule,
    VisualAcceptanceStatusComponent,
    // PrintJobStatusIconComponent,
    PrinterSelectorComponent,
    // BundleJobsListComponent,
    SearchBarComponent
  ],
  providers: [],

})
export class ComponentsModule {
}
